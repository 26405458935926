import { createBrowserRouter } from "react-router-dom";
import Home from "../views/home/home";
import Profile from "../views/profile/profile";
import AboutUs from "../views/about/about_us";
import News from "../views/news";
import ParishListing from "../views/parish_listing/parish_listing";
import CommitteeListing from "../views/committee_listing/committee_listing";
import ContactUs from "../views/contact_us/contact_us";
import Login from "../views/admin_portal/login";
import Dashboard from "../views/admin_portal/dashboard/dashboard";
import Users from "../views/admin_portal/users/users";
import CreateAdmin from "../views/admin_portal/users/create_admin/create_admin";
import UploadNews from "../views/admin_portal/upload_news/upload_news";
import YuvaDabazo from "../views/yuva_dabazo/yuva_dabazo";
import YuvaDabazoRegList from "../views/yuva_dabazo_reg_list/yuva_dabazo_reg_list";
import YuvaDabazoDetails from "../views/yuva_dabazo_details_page/yuva_dabazo_details";
import CalenderComiingSoon from "../views/calender_coming_soon/calender_coming_soon";
import RegistrationDone from "../views/yuva_dabazo/registration_done/registration_done";
import YuvaGaaz from "../views/yuva_gaaz/yuva_gaaz";

export const routes = [
  {
    path: "/",
    element: <Home />,
    title: "Home",
  },
  {
    path: "/profile",
    element: <Profile />,
    title: "Profile",
  },
  {
    path: "/about",
    element: <AboutUs />,
    title: "About",
  },
  {
    path: "/news",
    element: <News />,
    title: "News",
  },
  {
    path: "/parishes",
    element: <ParishListing />,
    title: "Parishes",
  },
  {
    path: "/parishes/parish",
    element: <CommitteeListing />,
    title: "Parish",
  },
  {
    path: "/contactus",
    element: <ContactUs />,
    title: "Contact Us",
  },
  {
    path: "/admin/login",
    element: <Login />,
    title: "Login",
  },
  {
    path: "/admin/home",
    element: <Dashboard />,
    title: "Home",
  },
  {
    path: "/admin/users",
    element: <Users />,
    title: "Users",
  },
  {
    path: "/admin/users/createAdmin",
    element: <CreateAdmin />,
    title: "Create Admin",
  },
  {
    path: "/admin/uploadNews",
    element: <UploadNews />,
    title: "Upload News",
  },
  {
    path: "/yuvadabazo",
    element: <YuvaDabazo />,
    title: "Yuva Dabazo",
  },
  {
    path: "/dabazoRegistrationList",
    element: <YuvaDabazoRegList />,
    title: "Dabazo Registration List",
  },
  {
    path: "/dabazoRegistrationList/:id",
    element: <YuvaDabazoDetails />,
    title: "Dabazo Registration Details",
  },
  {
    path: "/eventsCalendar",
    element: <CalenderComiingSoon />,
    title: "Calendar",
  },
  {
    path: "/yuvadabazo/registrationSuccess",
    element: <RegistrationDone />,
    title: "Registration Success",
  },
  {
    path: "/yuvagaaz",
    element: <YuvaGaaz />,
    title: "Yuva Gaaz",
  },
];

export default createBrowserRouter(routes);
