import {
  Avatar,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import { MAIN_THEME_COLOR } from "../../../../providers/theme/colors/colors";
import { collection, query } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ArrowForwardIos } from "@mui/icons-material";
import * as XLSX from "xlsx";

interface Props {}

function DataGrid(props: Props) {
  const {} = props;
  const firestore = useFirestore();
  const navigate = useNavigate();

  let labels = [
    {
      id: 1,
      name: "Sl. No",
    },
    {
      id: 2,
      name: "Parish",
    },
    {
      id: 3,
      name: "Deanery",
    },

    {
      id: 4,
      name: "President Name",
    },
    {
      id: 5,
      name: "Animator Name",
    },
    {
      id: 6,
      name: "Contact Number",
    },
  ];

  const collectionDataForYuvadabazo = collection(firestore, "yuvadabazo");
  const chapterCollectionQueryForYuvaDabazo = query(
    collectionDataForYuvadabazo
  );

  const { data: registrationList } = useFirestoreCollectionData(
    chapterCollectionQueryForYuvaDabazo
  );

  const handleExport = () => {
    // Flatten the data structure
    const flattenData = registrationList?.map((item) => {
      return {
        "President Name": item?.presidentName,
        "Deanery Name": item?.deaneryName.name,
        "Parish Name": item?.parishName.value,
        "Animator Name": item?.animatorName,
        "Contact Number": item?.contactNumber,
        Email: item?.email,
        "Total Participants": item?.others,
        "Updated At": item?.createdAt,
        "Participating Event List": item?.participatingEventList
          .map((e: any) => e?.name)
          .join(", "),
        "Futuristic Art Participant Name": item?.futuristicArtParticipantName,
        "Flower Carpet Participats Name": item?.flowerCarpetParticipantName
          .map((p: any) => p?.name)
          .join(", "),
        "Creative Walk Partipants Name": item?.creativeWalkPartipantsName
          .map((p: any) => p?.name)
          .join(", "),
        "Brain Wave Participant Name": item?.brainWaveParticipantName
          .map((p: any) => p?.name)
          .join(", "),
      };
    });

    // Convert to worksheet
    const ws = XLSX.utils.json_to_sheet(flattenData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

    // Export file
    XLSX.writeFile(wb, "yuvadabazo.xlsx");
  };

  console.log(registrationList, "registrationList");

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      height={"100%"}
      px={4}
      py={2}
    >
      <Box display={"flex"} flexDirection={"row"}>
        <Typography color="#000000" variant="body2">
          Download as :
        </Typography>

        <Box onClick={handleExport} ml={1} sx={{ cursor: "pointer" }}>
          <img
            src="/images/icons/excel.jpeg"
            style={{ height: 20, width: 20 }}
          />
        </Box>
      </Box>
      <Box display={"flex"} flexDirection={"row"}>
        {labels?.map((item, index) => {
          if (isMobile && index < 2) {
            return (
              <Box display={"flex"} flex={1}>
                <Typography color={MAIN_THEME_COLOR} fontWeight={600}>
                  {item?.name}
                </Typography>
              </Box>
            );
          }
          if (!isMobile) {
            return (
              <Box display={"flex"} flex={1}>
                <Typography color={MAIN_THEME_COLOR} fontWeight={600}>
                  {item?.name}
                </Typography>
              </Box>
            );
          }
        })}
      </Box>
      <Box display={"flex"} flexDirection={"column"}>
        {registrationList?.length === 0 ? (
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
            mt={8}
          >
            <Typography fontWeight={600}>No Data Available</Typography>
          </Box>
        ) : (
          registrationList?.map?.((item, index) => {
            return (
              <Box
                display={"flex"}
                flexDirection={"row"}
                py={4}
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  navigate(`/dabazoRegistrationList/${item?.NO_ID_FIELD}`);
                }}
              >
                <Typography display={"flex"} flex={1}>
                  {index + 1}
                </Typography>
                <Box display={"flex"} flex={1}>
                  <Typography>{item?.parishName?.value}</Typography>
                  {isMobile && (
                    <Box
                      display={"flex"}
                      width={"100%"}
                      ml={2}
                      justifyContent={"flex-end"}
                    >
                      <ArrowForwardIos />
                    </Box>
                  )}
                </Box>

                {!isMobile && (
                  <>
                    <Typography display={"flex"} flex={1}>
                      {item?.deaneryName?.name}
                    </Typography>
                    <Typography display={"flex"} flex={1}>
                      {item?.presidentName}
                    </Typography>
                    <Typography display={"flex"} flex={1}>
                      {item?.animatorName}
                    </Typography>
                    <Box display={"flex"} flex={1}>
                      <Typography>{item?.contactNumber}</Typography>
                      {!isMobile && (
                        <Box
                          display={"flex"}
                          width={"100%"}
                          ml={2}
                          justifyContent={"flex-end"}
                        >
                          <ArrowForwardIos />
                        </Box>
                      )}
                    </Box>

                    {/* <Box display={"flex"} flex={1} flexDirection={"row"}>
                    <Typography display={"flex"} textTransform={"lowercase"}>
                      {item?.participatingEventList?.[0]?.name}
                    </Typography>
                    {item?.participatingEventList?.length > 1 && (
                      <Typography ml={1}>etc...</Typography>
                    )}
                  </Box> */}
                  </>
                )}
              </Box>
            );
          })
        )}
      </Box>
    </Box>
  );
}

export default DataGrid;
