// Youthcommission24
import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/main_layout/main_layout";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { Add, Close, Remove } from "@mui/icons-material";
import { addDoc, collection, query } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData } from "reactfire";
import { useFeedback } from "../../providers/feedback/feedback";
import { isMobile } from "react-device-detect";
import { useNavigate } from "react-router-dom";

interface Props {}
interface Participant {
  participantName1?: string;
  participantName2?: string;
  participantName3?: string;
}

// Type Definitions
type Deanery = {
  id: number;
  name: string;
};

type Parish = {
  id: number;
  value: string;
};

// Deanery List
const deaneryList: Deanery[] = [
  { id: 1, name: "Kundapur" },
  { id: 2, name: "Udupi" },
  { id: 3, name: "Karkal" },
  { id: 4, name: "Kallianpur" },
  { id: 5, name: "Shirva" },
];

// Parish Lists
const kundapurParishList: Parish[] = [
  { id: 1, value: "Basrur" },
  { id: 2, value: "Belve" },
  { id: 3, value: "Byndoor" },
  { id: 4, value: "Kundapur" },
  { id: 5, value: "Gangolli" },
  { id: 6, value: "Kota" },
  { id: 7, value: "Koteshwar" },
  { id: 8, value: "Padukone" },
  { id: 9, value: "Piusnagar" },
  { id: 10, value: "Tallur" },
  { id: 11, value: "Trasi" },
  { id: 12, value: "Kerekatte" },
  { id: 13, value: "Kandlur" },
];

const karkalaParishList: Parish[] = [
  { id: 14, value: "Ajekar" },
  { id: 15, value: "Hirgan" },
  { id: 16, value: "Miyar" },
  { id: 17, value: "Karkal-Attur" },
  { id: 18, value: "Karkal-Town" },
  { id: 19, value: "Parapady" },
  { id: 20, value: "Nakre " },
  { id: 21, value: "Kanajar" },
  { id: 22, value: "Kera " },
];

const shirvaParishList: Parish[] = [
  { id: 23, value: "Belman" },
  { id: 24, value: "Kalathur" },
  { id: 25, value: "Kelmbet" },
  { id: 26, value: "Mudarangadi" },
  { id: 27, value: "Mukamar" },
  { id: 28, value: "Palimar" },
  { id: 29, value: "Pamboor " },
  { id: 30, value: "Mundkur" },
  { id: 31, value: "Pernal " },
  { id: 32, value: "Shirva" },
  { id: 33, value: "Pangla " },
  { id: 34, value: "Pilar " },
];

const udupiParishList: Parish[] = [
  { id: 35, value: "Moodubelle" },
  { id: 36, value: "Kalmady" },
  { id: 37, value: "Katapady" },
  { id: 38, value: "Kuntalnagar" },
  { id: 39, value: "Manipal" },
  { id: 40, value: "Perampally" },
  { id: 41, value: "Udupi" },
  { id: 42, value: "Udyavar" },
  { id: 43, value: "Yermal" },
];

const kallianpurParishList: Parish[] = [
  { id: 35, value: "Barkur" },
  { id: 36, value: "Brahmavar" },
  { id: 37, value: "Kolalgiri" },
  { id: 38, value: "Kallianpur-Milagres" },
  { id: 39, value: "Kallianpur-Mount Rosary" },
  { id: 40, value: "Thottam" },
  { id: 41, value: "Kemman" },
  { id: 42, value: "Pethri" },
  { id: 43, value: "Sastan" },
];
// Mapping deaneries to their respective parish lists
const parishListMap: Record<string, Parish[]> = {
  Kundapur: kundapurParishList,
  Karkal: karkalaParishList,
  Shirva: shirvaParishList,
  Udupi: udupiParishList,
  Kallianpur: kallianpurParishList,

  // Add other parish lists for remaining deaneries as needed
};

function YuvaDabazo(props: Props) {
  const { showSnackbar } = useFeedback();
  const navigate = useNavigate();
  const firestore = useFirestore();
  const [parishList, setParishList] = useState<Parish[]>([]);
  const [presidentName, setPresidentName] = useState("");
  const [animatorName, setAnimatorName] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [email, setEmail] = useState("");
  const [creativeWalk, setCreativeWalk] = useState([
    { name: "", id: 1 },
    { name: "", id: 2 },
    { name: "", id: 3 },
    { name: "", id: 4 },
  ]);
  const [eventSelectedList, setEventSelectedList] = useState<
    { id: number; name: string }[]
  >([]);
  const [flowerCarpetParticipant, setFlowerCarpetParticipant] = useState<
    {
      participantName1?: string;
      participantName2?: string;
    }[]
  >([{ participantName1: "" }, { participantName2: "" }]);
  const [futuristicArtParticipantName, setFuturisticArtParticipantName] =
    useState("");
  const [brainWaveParticipantName, setBrainWaveParticipantName] = useState<
    Participant[]
  >([
    { participantName1: "" },
    { participantName2: "" },
    { participantName3: "" },
  ]);
  // const [others, setOthers] = useState([{ name: "", id: 1 }]);
  const [otherAttendeesCount, setOtherAttendeesCount] = useState<number | null>(
    null
  );
  const [error, setError] = useState("");
  const [_selectedDeanery, _setSelectedDeanery] = useState<{
    id: Number;
    name: string;
  } | null>(null);
  const [_selectedParish, _setSelectedParish] = useState<{
    id: Number;
    value: string;
  } | null>(null);

  const {} = props;

  let eventItemList = [
    {
      id: 1,
      name: "Creative Walk",
    },
    {
      id: 2,
      name: "Flower Carpet",
    },
    {
      id: 3,
      name: "Brain Wave",
    },
    {
      id: 4,
      name: "Futuristic Art",
    },
    // {
    //   id: 5,
    //   name: "Other Attendees",
    // },
  ];

  const collectionDataForYuvadabazo = collection(firestore, "yuvadabazo");
  const chapterCollectionQueryForYuvaDabazo = query(
    collectionDataForYuvadabazo
    // orderBy("order", "asc")
  );

  const { data: registrationList } = useFirestoreCollectionData(
    chapterCollectionQueryForYuvaDabazo
  );

  function submit() {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
    } else {
      const usersCollection = collection(firestore, "yuvadabazo");
      let error = false;
      let registrationAlreadyDone = false;
      if (eventSelectedList?.filter((eventLits) => eventLits.id === 1).length) {
        creativeWalk?.map((participant) => {
          if (participant.name === "") {
            error = true;
          }
        });
      }
      // if (eventSelectedList?.filter((eventLits) => eventLits.id === 5).length) {
      //   others?.map((participant) => {
      //     if (participant.name === "") {
      //       error = true;
      //     }
      //   });
      // }
      if (eventSelectedList?.filter((eventLits) => eventLits.id === 5).length) {
        if (!otherAttendeesCount) {
          error = true;
        }
      }

      if (eventSelectedList?.filter((eventLits) => eventLits.id === 2).length) {
        flowerCarpetParticipant?.map((participant) => {
          if (
            participant.participantName1 === "" ||
            participant?.participantName2 === ""
          ) {
            error = true;
          }
        });
      }
      if (eventSelectedList?.filter((eventLits) => eventLits.id === 3).length) {
        brainWaveParticipantName?.map((participant) => {
          if (
            participant.participantName1 === "" ||
            participant?.participantName2 === "" ||
            participant?.participantName3 === ""
          ) {
            error = true;
          }
        });
      }
      if (eventSelectedList?.filter((eventLits) => eventLits.id === 4).length) {
        if (futuristicArtParticipantName === "") {
          error = true;
        }
      }
      if (error) {
        showSnackbar("Please fill in all details for the selected event(s)");
      } else {
        registrationList?.map((item) => {
          if (item?.parishName?.id === _selectedParish?.id) {
            showSnackbar("You have already registered.!!", "#f44336");
            registrationAlreadyDone = true;
          }
        });

        if (!registrationAlreadyDone) {
          addDoc(usersCollection, {
            createdAt: Date().toString(),
            updatedAt: Date().toString(),
            deaneryName: _selectedDeanery,
            parishName: _selectedParish,
            presidentName: presidentName,
            contactNumber: contactNumber,
            animatorName: animatorName,
            participatingEventList: eventSelectedList,
            futuristicArtParticipantName: futuristicArtParticipantName,
            brainWaveParticipantName: [
              { id: 1, name: brainWaveParticipantName?.[0]?.participantName1 },
              { id: 2, name: brainWaveParticipantName?.[1]?.participantName2 },
              { id: 3, name: brainWaveParticipantName?.[2]?.participantName3 },
            ],
            flowerCarpetParticipantName: [
              { id: 1, name: flowerCarpetParticipant[0]?.participantName1 },
              { id: 1, name: flowerCarpetParticipant[1]?.participantName2 },
            ],
            creativeWalkPartipantsName: creativeWalk,
            others: otherAttendeesCount,
            email: email,
          }).then((res) => {
            showSnackbar(
              "Registration successful! Looking forward to seeing you at Yuva Dabazo 2024!"
            );
            navigate("/yuvadabazo/registrationSuccess");
            window.scrollTo(0, 0);
          });
        }
      }
    }
  }

  useEffect(() => {
    if (!eventSelectedList?.filter((item) => item?.id === 1).length) {
      setCreativeWalk([
        { name: "", id: 1 },
        { name: "", id: 2 },
        { name: "", id: 3 },
        { name: "", id: 4 },
      ]);
    }
    if (!eventSelectedList?.filter((item) => item?.id === 2).length) {
      setFlowerCarpetParticipant([
        { participantName1: "" },
        { participantName2: "" },
      ]);
    }
    if (!eventSelectedList?.filter((item) => item?.id === 3).length) {
      setBrainWaveParticipantName([
        { participantName1: "" },
        { participantName2: "" },
        { participantName3: "" },
      ]);
    }
    if (!eventSelectedList?.filter((item) => item?.id === 4).length) {
      setFuturisticArtParticipantName("");
    }
  }, [eventSelectedList]);

  // Handle deanery change
  const handleDeaneryChange = (
    event: React.SyntheticEvent<Element, Event>,
    value: Deanery | null
  ) => {
    if (value) {
      _setSelectedDeanery({ id: value.id, name: value.name });
      setParishList(parishListMap[value.name] || []); // Set parish list based on the selected deanery
      _setSelectedParish(null); // Reset parish selection when deanery changes
    } else {
      _setSelectedDeanery(null);
      setParishList([]); // Reset parish list when no deanery is selected
    }
  };

  return (
    <MainLayout>
      <Box my={4}>
        <Box>
          <Typography
            fontWeight={500}
            textAlign={"center"}
            variant={isMobile ? "h5" : "h4"}
          >
            YUVA DABAZO- 2024
          </Typography>
          <Typography fontWeight={500} textAlign={"center"} variant="h6">
            Registration Form
          </Typography>
        </Box>
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xl={12} lg={12} sm={12} md={12} xs={12}>
              <Box display={"flex"} flexDirection={"column"}>
                <Typography variant="caption" fontWeight={600}>
                  Note: For any modifications or clarifications regarding your
                  registration, please reach out to :
                </Typography>
                <Typography variant="caption" fontWeight={600}>
                  Fr.Steevan Fernandes -9945733168, Godwin Mascarenhas -
                  8861992590, Nithin Baretto - 7406411821
                </Typography>
              </Box>
            </Grid>
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  Deanery name <span style={{ color: "red" }}>*</span>:
                </Typography>
                <Autocomplete
                  fullWidth
                  value={
                    deaneryList.find(
                      (option) => _selectedDeanery?.id === option.id
                    ) || null
                  }
                  onChange={handleDeaneryChange}
                  options={deaneryList}
                  getOptionLabel={(option) => option.name || ""}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </Box>
            </Grid>
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  Parish name <span style={{ color: "red" }}>*</span>:
                </Typography>
                <Autocomplete
                  fullWidth
                  value={
                    parishList.find(
                      (option: Parish) => _selectedParish?.id === option.id
                    ) || null
                  }
                  onChange={(e: React.SyntheticEvent, value: Parish | null) =>
                    _setSelectedParish(value)
                  }
                  options={parishList}
                  getOptionLabel={(option: Parish) => option.value || ""}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                  disabled={!parishList.length} // Disable when no parishes are available
                />
              </Box>
            </Grid>
            {!isMobile && <Grid item xl={4} md={4} sm={12} xs={12}></Grid>}
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  President name <span style={{ color: "red" }}>*</span>:
                </Typography>
                <TextField
                  fullWidth
                  value={presidentName}
                  onChange={(e) => {
                    setPresidentName(e.target.value);
                  }}
                />
              </Box>
            </Grid>

            <Grid item xl={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  Animator name <span style={{ color: "red" }}>*</span>:
                </Typography>
                <TextField
                  fullWidth
                  value={animatorName}
                  onChange={(e) => {
                    setAnimatorName(e.target.value);
                  }}
                />
              </Box>
            </Grid>
            {!isMobile && <Grid item xl={4} md={4} sm={12} xs={12}></Grid>}
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  Contact number <span style={{ color: "red" }}>*</span>:
                </Typography>
                <TextField
                  fullWidth
                  inputProps={{
                    inputMode: "numeric",
                    pattern: "[0-9]*",
                    maxLength: 10,
                  }}
                  value={contactNumber}
                  onChange={(e) => {
                    const inputValue = e.target.value.replace(/\D/g, "");
                    setContactNumber(inputValue);
                  }}
                />
              </Box>
            </Grid>

            <Grid item xl={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  Email <span style={{ color: "red" }}>*</span>:
                </Typography>
                <TextField
                  fullWidth
                  type="email"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setError("");
                  }}
                  helperText={error}
                  FormHelperTextProps={{
                    sx: { color: "red" }, // Set helperText color to red
                  }}
                />
              </Box>
            </Grid>
            <Grid item xl={4} md={4} sm={12} xs={12}></Grid>
            <Grid item xl={4} md={4} sm={12} xs={12}>
              <Box>
                <Typography mb={1} fontWeight={500}>
                  Participation Count <span style={{ color: "red" }}>*</span>:
                </Typography>
                <Box display={"flex"} flexDirection={"row"}>
                  <TextField
                    fullWidth
                    inputProps={{
                      inputMode: "numeric",
                      pattern: "[1-9]*",
                      maxLength: 100,
                    }}
                    value={otherAttendeesCount}
                    onChange={(e) => {
                      const inputValue = e.target.value.replace(/\D/g, "");
                      setOtherAttendeesCount(+inputValue);
                    }}
                  />
                </Box>
              </Box>
              <Box>
                <Typography variant="caption" fontWeight={600}>
                  Note: Participation count includes all competitors at Parish &
                  Deanery level competitions, plus all ICYM youths attending the
                  Convention of your Parish.
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={3} mt={2}>
            <Grid item xl={12} md={12} sm={12} xs={12}>
              <Typography fontWeight={500}>Select Event :</Typography>
            </Grid>
            {eventItemList?.map((item: { id: number; name: string }) => {
              return (
                <Grid item xl={2} md={2} sm={12} xs={12}>
                  <Box display={"flex"} flexDirection={"row"}>
                    <Checkbox
                      name="Flower Carpet"
                      checked={
                        !!eventSelectedList?.filter(
                          (filteritem) => item?.id === filteritem?.id
                        )?.length
                      }
                      onChange={() => {
                        let filteredItem = eventSelectedList?.filter(
                          (filteritem) => item?.id === filteritem?.id
                        );

                        if (filteredItem?.length) {
                          let itemDeSelected = eventSelectedList?.filter(
                            (filteritem) => item?.id !== filteritem?.id
                          );
                          setEventSelectedList([...itemDeSelected]);
                        } else {
                          setEventSelectedList([...eventSelectedList, item]);
                        }
                      }}
                    />
                    <Typography mt={1} fontWeight={500}>
                      {item?.name}
                    </Typography>
                  </Box>
                </Grid>
              );
            })}
          </Grid>
          {eventSelectedList?.map((event) => {
            if (event.id === 2) {
              return (
                <Grid container spacing={3}>
                  <Grid item xl={12} md={12} sm={12} xs={12}>
                    <Typography mt={4} variant="h6">
                      Flower Carpet :
                    </Typography>
                  </Grid>
                  <Grid item xl={4} md={4} sm={12} xs={12}>
                    <Box>
                      <Typography mb={1} fontWeight={500}>
                        Participant name 1:
                      </Typography>
                      <TextField
                        fullWidth
                        value={flowerCarpetParticipant?.[0].participantName1}
                        onChange={(e) => {
                          const updatedItems = flowerCarpetParticipant?.map(
                            (item, index) =>
                              index === 0
                                ? { ...item, participantName1: e.target.value }
                                : item
                          );
                          setFlowerCarpetParticipant(updatedItems);
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xl={4} md={4} sm={12} xs={12}>
                    <Box>
                      <Typography mb={1} fontWeight={500}>
                        Participant name 2:
                      </Typography>
                      <TextField
                        fullWidth
                        value={flowerCarpetParticipant?.[1]?.participantName2}
                        onChange={(e) => {
                          const updatedItems = flowerCarpetParticipant?.map(
                            (item, index) =>
                              index === 1
                                ? { ...item, participantName2: e.target.value }
                                : item
                          );
                          setFlowerCarpetParticipant(updatedItems);
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              );
            }
            if (event?.id === 3) {
              return (
                <Grid container spacing={3}>
                  <Grid item xl={12} md={12} sm={12} xs={12}>
                    <Typography mt={4} variant="h6">
                      BRAIN WAVE :
                    </Typography>
                  </Grid>
                  <Grid item xl={4} md={4} sm={12} xs={12}>
                    <Box>
                      <Typography mb={1} fontWeight={500}>
                        Participant name 1:
                      </Typography>
                      <TextField
                        fullWidth
                        value={brainWaveParticipantName?.[0].participantName1}
                        onChange={(e) => {
                          const updatedItems = brainWaveParticipantName.map(
                            (item, index) =>
                              index === 0
                                ? { ...item, participantName1: e.target.value }
                                : item
                          );
                          setBrainWaveParticipantName(updatedItems);
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xl={4} md={4} sm={12} xs={12}>
                    <Box>
                      <Typography mb={1} fontWeight={500}>
                        Participant name 2:
                      </Typography>
                      <TextField
                        fullWidth
                        value={brainWaveParticipantName?.[1].participantName2}
                        onChange={(e) => {
                          const updatedItems = brainWaveParticipantName.map(
                            (item, index) =>
                              index === 1
                                ? { ...item, participantName2: e.target.value }
                                : item
                          );
                          setBrainWaveParticipantName(updatedItems);
                        }}
                      />
                    </Box>
                  </Grid>
                  <Grid item xl={4} md={4} sm={12} xs={12}></Grid>
                  <Grid item xl={4} md={4} sm={12} xs={12}>
                    <Box>
                      <Typography mb={1} fontWeight={500}>
                        Participant name 3:
                      </Typography>
                      <TextField
                        fullWidth
                        value={brainWaveParticipantName?.[2].participantName3}
                        onChange={(e) => {
                          const updatedItems = brainWaveParticipantName.map(
                            (item, index) =>
                              index === 2
                                ? { ...item, participantName3: e.target.value }
                                : item
                          );
                          setBrainWaveParticipantName(updatedItems);
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              );
            }
            if (event?.id === 4) {
              return (
                <Grid container spacing={3}>
                  <Grid item xl={12} md={12} sm={12} xs={12}>
                    <Typography mt={4} variant="h6">
                      FUTURISTIC ART :
                    </Typography>
                  </Grid>
                  <Grid item xl={4} md={4} sm={12} xs={12}>
                    <Box>
                      <Typography mb={1} fontWeight={500}>
                        Participant name 1:
                      </Typography>
                      <TextField
                        fullWidth
                        value={futuristicArtParticipantName}
                        onChange={(e) => {
                          setFuturisticArtParticipantName(e.target.value);
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
              );
            }
            if (event?.id === 1) {
              return (
                <Grid container spacing={3}>
                  <Grid item xl={12} md={12} sm={12} xs={12}>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      mt={4}
                    >
                      <Typography variant="h6">CREATIVE WALK :</Typography>
                      <Button
                        variant="contained"
                        sx={{
                          height: "fit-content",
                          width: "fit-content",
                        }}
                        onClick={() => {
                          setCreativeWalk([
                            ...creativeWalk,
                            { name: "", id: creativeWalk?.length + 1 },
                          ]);
                        }}
                      >
                        <Add sx={{ color: "#ffffff" }} />
                        {/* <Typography ml={1} fontWeight={500} variant="body2">
                          Add Participant
                        </Typography> */}
                      </Button>
                    </Box>
                  </Grid>
                  {creativeWalk?.map((item, index) => {
                    return (
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <Box>
                          <Typography mb={1} fontWeight={500}>
                            Participant name {index + 1}:
                          </Typography>
                          <Box display={"flex"} flexDirection={"row"}>
                            <TextField
                              fullWidth
                              value={item?.name}
                              onChange={(e) => {
                                // Create a new array with the updated name
                                const updatedItems = creativeWalk.map(
                                  (item2, index2) =>
                                    index2 === index
                                      ? { ...item2, name: e.target.value }
                                      : item2
                                );
                                setCreativeWalk(updatedItems); // Update state with the new array
                              }}
                            />
                            {index + 1 > 4 && (
                              <IconButton
                                onClick={() => {
                                  let filteredItems = creativeWalk?.filter(
                                    (item2, index2) => index2 !== index
                                  );
                                  console.log(filteredItems);

                                  setCreativeWalk(filteredItems);
                                }}
                              >
                                <Close />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })}
                </Grid>
              );
            }

            if (event?.id === 5) {
              return (
                <Grid container spacing={3}>
                  <Grid item xl={12} md={12} sm={12} xs={12}>
                    <Box
                      display={"flex"}
                      flexDirection={"row"}
                      justifyContent={"space-between"}
                      alignItems={"center"}
                      mt={4}
                    >
                      {/* <Typography variant="h6">Other Attendees :</Typography> */}
                      {/* <Button
                        variant="contained"
                        sx={{
                          height: "fit-content",
                          width: "fit-content",
                        }}
                        onClick={() => {
                          setOthers([
                            ...others,
                            { name: "", id: others?.length + 1 },
                          ]);
                        }}
                      >
                        <Add sx={{ color: "#ffffff" }} />
                      </Button> */}
                    </Box>
                  </Grid>
                  {/* <Grid item xl={4} md={4} sm={12} xs={12}>
                    <Box>
                      <Typography mb={1} fontWeight={500}>
                        Participant Count :
                      </Typography>
                      <Box display={"flex"} flexDirection={"row"}>
                        <TextField
                          fullWidth
                          inputProps={{
                            inputMode: "numeric",
                            pattern: "[1-9]*",
                            maxLength: 100,
                          }}
                          value={otherAttendeesCount}
                          onChange={(e) => {
                            const inputValue = e.target.value.replace(
                              /\D/g,
                              ""
                            );
                            setOtherAttendeesCount(+inputValue);
                          }}
                        />
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="caption">
                        Note : Please include all participants competing at both
                        parish and deanery levels in your count.
                      </Typography>
                    </Box>
                  </Grid> */}

                  {/* {others?.map((item, index) => {
                    return (
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <Box>
                          <Typography mb={1} fontWeight={500}>
                            Participant name {index + 1}:
                          </Typography>
                          <Box display={"flex"} flexDirection={"row"}>
                            <TextField
                              fullWidth
                              value={item?.name}
                              onChange={(e) => {
                                const updatedItems = others.map(
                                  (item2, index2) =>
                                    index2 === index
                                      ? { ...item2, name: e.target.value }
                                      : item2
                                );
                                setOthers(updatedItems);
                              }}
                            />
                            {index + 1 > 1 && (
                              <IconButton
                                onClick={() => {
                                  let filteredItems = others?.filter(
                                    (item2, index2) => index2 !== index
                                  );

                                  setOthers(filteredItems);
                                }}
                              >
                                <Close />
                              </IconButton>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                    );
                  })} */}
                </Grid>
              );
            }
          })}
        </Box>
        <Grid container>
          <Grid lg={12} md={12} sm={12} xs={12}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              mt={5}
            >
              <Button
                sx={{
                  width: "fit-content",
                  minWidth: 300,
                  justifyContent: "center",
                }}
                variant="contained"
                onClick={submit}
                disabled={
                  !_selectedParish?.id ||
                  contactNumber.length !== 10 ||
                  !animatorName ||
                  !presidentName ||
                  !email ||
                  !otherAttendeesCount
                }
              >
                <Typography fontWeight={500}>Register</Typography>
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
}

export default YuvaDabazo;
