import React from "react";
import MainLayout from "../../layouts/main_layout/main_layout";
import { Box, Grid, Typography } from "@mui/material";

interface Props {}

function CalenderComiingSoon(props: Props) {
  const {} = props;

  return (
    <MainLayout>
      <Box mt={3} mb={4}>
        <Typography variant="h5" fontWeight={"600"} textAlign={"center"}>
          Events Calendar Coming Soon!
        </Typography>
        <Grid container spacing={2}>
          <Grid item xl={6} md={6} sm={12} xs={12}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              height={"100%"}
            >
              <Typography mt={2}>
                Thank you for visiting our Events Calendar section. We’re
                working hard to bring you a complete and updated calendar to
                help you manage and view upcoming parish, deanery, and
                diocese-level events. Currently, this feature is being finalized
                and will be available soon. The full calendar will be
                inaugurated during
                <Typography component="span" fontWeight="bold">
                  {" "}
                  Yuva Dabazo - 2024
                </Typography>
                . We appreciate your patience and look forward to providing you
                with a valuable tool for event management.
              </Typography>
            </Box>
          </Grid>
          <Grid display={"flex"} item md={6} lg={6} sm={12} xs={12}>
            <Box
              display={"flex"}
              height="100%"
              width={"100%"}
              sx={{ alignItems: "center", justifyContent: "center" }}
            >
              <Box
                component="img"
                src="/images/icons/coming_soon.svg"
                alt=""
                sx={{
                  borderRadius: 2,
                  width: { xs: "100%", sm: "100%", md: "100%", lg: 350 },
                  height: { xs: "auto", sm: "auto", md: "auto", lg: 350 },
                  maxHeight: { xs: "none", md: "100%" },
                  maxWidth: { xs: "none", md: "100%" },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
}

export default CalenderComiingSoon;
